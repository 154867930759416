<template>
  <span class="material-symbols-outlined" :style="`${fill} ${size}`" translate="no">
    {{ props.icon }}
  </span>
</template>

<script setup>
// ✅ Vue imports
import { computed } from 'vue';
// 📦 External packages imports

// 👨‍💻 Internal packages imports

// 1️⃣ Props, Emits, ...
const props = defineProps({
  icon: {
    type: String,
    default: 'menu',
  },
  size: {
    type: Number,
    default: 24,
  },
  fill: {
    type: Boolean,
    default: false,
  },
});

// 2️⃣ General properties, methods, ...

// 3️⃣ Functions

// 4️⃣ Computed properties
const fill = computed(
  () => {
    if (props.fill) {
      return `font-variation-settings: "FILL" ${props.fill ? '1;' : '0;'}`;
    }
    return '';
  },
);

const size = computed(
  () => {
    if (props.size) {
      return `font-size:${props.size}px;`;
    }
    return '';
  },
);

// 5️⃣ Lifecycle hooks
</script>
